import { Button, Card, Tooltip } from "@mui/material";
import React from "react";

const Sidebar = (props) => {
  const {
    open,
    title,
    onClickFn,
    bottom,
    right,
    left,
    disabled,
    component,
    icon,
    forwardIcon,
    backwardIcon,
  } = props;

  const handle = {
    paddingFn: () => {
      switch (title) {
        case "Expand lenders":
          return "12px 12px 12px 12px";
        case "Notes":
          return "12px 0px";
        case "Activity log":
          return "12px 0px";
        default:
          break;
      }
    },
    widthFn: () => {
      switch (title) {
        case "Expand lenders":
          return `calc(${open ? 75 : 20}vw - 25px)`;
        case "Notes":
          return `${open ? "calc(25vw - 25px)" : 0}`;
        case "Activity log":
          return `${open ? "calc(25vw - 25px)" : 0}`;
        default:
          break;
      }
    },
    leftFn: () => {
      switch (title) {
        case "Notes":
          return left ? `${open ? "calc(25vw - 25px)" : "0px"}` : "";
        case "SMS (coming soon)":
          return left ? `${open ? "calc(25vw - 24px)" : "0px"}` : "";
        case "Emails (coming soon)":
          return left ? `${open ? "calc(25vw - 25px)" : "0px"}` : "";
        case "Activity log":
          return left ? `${open ? "calc(25vw - 25px)" : "0px"}` : "";
        default:
          break;
      }
    },
    topFn: () => {
      switch (title) {
        case "Expand lenders":
          return "146px";
        case "Notes":
          return "146px";
        case "SMS (coming soon)":
          return "315px";
        case "Emails (coming soon)":
          return "260px";
        case "Activity log":
          return "203px";
        default:
          break;
      }
    },
  };

  return (
    <div>
      <Tooltip
        title={title}
        placement={title === "Expand lenders" ? "left" : "right"}
      >
        <Button
          disabled={disabled}
          color={`${open ? "primary" : "secondary"}`}
          elevation={1}
          variant={`${open ? "contained" : "contained"}`}
          style={{
            transition: "all 0.5s ease-in-out 0s",
            padding: "5px 5px",
            minWidth: 0,
            position: "fixed",
            borderColor: "rgba(0, 0, 0, 0.10)",
            right: right
              ? `calc(${open ? 75 : 20}vw - ${open ? "25px" : "35px"})`
              : "",
            left: handle.leftFn(),
            bottom: left && !bottom && "742px",
            top: handle.topFn(),
            writingMode: "vertical-rl",
            textOrientation: "sideways-right",
            marginTop: "25px",
            zIndex: open ? "999" : "",
          }}
          onClick={onClickFn}
        >
          {icon ? icon : open ? forwardIcon : backwardIcon}
          {/* {open ? "Close" : title } */}
        </Button>
      </Tooltip>

      <Card
        id="productSelectorWrapper"
        elevation={2}
        style={{
          background: "#fff",
          padding: handle.paddingFn(),
          width: handle.widthFn(),
          position: "fixed",
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(100vh - 63px)",
          scrollbarColor: "white rebeccapurple",
          scrollbarWidth: "thin",
          right: right ? 0 : "",
          left: left ? 0 : "",
          top: 64,
          zIndex: 1000,
          transition: "all 0.5s ease-in-out 0s",
        }}
        sx={{
          "&.MuiCard-root::-webkit-scrollbar": {
            width: "7px",
            height: "7px",
          },
          "&.MuiCard-root::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&.MuiCard-root::-webkit-scrollbar-thumb": {
            backgroundColor: "#999",
          },
          "&.MuiCard-root::-webkit-scrollbar-thumb:hover": {
            background: "#616161",
          },
        }}
      >
        {component}
      </Card>
      {/* <Button
        disabled={disabled}
        color={`${open ? "primary" : "primary"}`}
        elevation={1}
        variant={`${open ? "contained" : "outlined"}`}
        style={{
          transform: "rotate(90deg)",
          transition: "all 0.5s ease-in-out 0s",
          padding: "5px 5px",
          minWidth: 0,
          position: "fixed",
          borderColor: "rgba(0, 0, 0, 0.10)",
          right: right ? `calc(${open ? 75 : 20}vw - ${open ? "35px" : "72px"})` : "",
          left: handle.leftFn(),
          bottom: left && !bottom ? 480 : bottom ? bottom : "",
          writingMode: "vertical-rl",
          textOrientation: "sideways-right",
          marginTop: "25px",
          zIndex: open ? "999" : ""
        }}
        onClick={onClickFn}
      >
        {open ? "Close" : title}
      </Button> */}
    </div>
  );
};

export default Sidebar;
