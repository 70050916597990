import React, { useCallback, useState } from "react";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllUsers, userSelector , saveValue } from "src/store/slices/userSlice";
import { useNavigate, userNavigate } from "react-router-dom";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { updateApplication } from "src/store/slices/applicationFormSlice";
import StatusCell from "src/components/User/StatusCell";
import DataGridTable from "../dataGridTable/DataGridTable";
// import { usersSelector, getAllUsers } from "src/store/slices/usersSlice";

export default function UserList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector)
  const usersState = useSelector(userSelector);
  const users = usersState.users
  const [rows, setRows] = useState([]);
  // const [page, setPage] = useState(0);
  const [params, setParams] = useState({ offset: 1 });
  const {page} = usersState

  const updateApplicationStatus = ({_id, status}) => {
    const updatedRows = rows.map((row) =>
      row.id === _id ? { ...row, status: status } : row
    );
    setRows(updatedRows);
  };

  const masterColumns = [
    {
      field: "date",
      headerName: "Date",
      width: 100,
      // editable: true,
    },
    {
      field: "brokerOrganisation",
      headerName: "Broker",
      width: 250,
      // editable: true,
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      // editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      // editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      width: 130,
      // editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 380,
      // editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      // editable: true,
      // type: 'singleSelect',
      // valueOptions: ['draft', 'Wire transfer', 'Cash'],
      width: 150,
      editable: true,
      renderEditCell: (params) => (
        <StatusCell params={params} updateApplicationStatus={updateApplicationStatus} />
      ),
    },

    {
      field: "cursor",
      headerName: "Cursor",
      type: "string",
      width: 0,
      editable: false,
      hide: true
    },
  ];

  // const queryOptions = React.useMemo(
  //   () => ({
  //     cursor: mapPageToNextCursor.current[page - 1],
  //     pageSize: 10,
  //   }),
  //   [page],
  // );

  // const { isLoading, data, pageInfo } = useQuery(queryOptions);

  const handlePageChange = (newPage) => {
    // We have the cursor, we can allow the page transition.
    // if (newPage === 0 || applications.nextPage) {
    handleGetAllUsers(user, newPage + 1);
    setPage(newPage)
    // }
  };

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = React.useState(
    users?.totalDocs || 0,
  );

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      users?.totalDocs !== undefined
        ? users?.totalDocs
        : prevRowCountState,
    );
  }, [users?.totalDocs, setRowCountState]);

  function handleGetAllUsers(userId, cursor) {
    dispatch(getAllUsers({ userId, cursor }));
  }

  useEffect(() => {
    console.log("mapPageToNextCursor", users?.page);
    if (user) handleGetAllUsers(user, users?.page || 1);
  }, []);

  useEffect(() => {
    console.log("changed")
    setRows(
      users?.docs?.map((doc) => {
        // console.log("setting")
        const date = new Date(doc.createdAt);
        // console.log("doc", doc);
        return {
          id: doc?._id,
          firstName: doc?.firstName,
          lastName: doc?.lastName,
          brokerOrganisation: doc?.organisation?.name,
          phoneNumber: doc?.phoneNumber?.number,
          email: doc?.email?.address,
          userId: doc._id,
          cursor: users?.page,
          status: doc?.status,
          date: `${moment(date).format("D/M")} ${moment(date).format("H:mm")}`,
        };
      })
    );
    // console.log("rows", rows);

  }, [users]);

  const handleCellEditCommit = useCallback(
    ({ id, field, value }) => {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      setRows(updatedRows);
    },
    [rows]
  );

  useEffect(() => {
    if (params?.offset) {
      handleGetAllUsers(user, params?.offset || 1)
      dispatch(saveValue({ page: params?.offset }));
    }
  }, [params?.offset]);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={0}
      style={{ padding: "20px" }}
    >
      <Grid item >
        <Typography
          variant="h1"
          style={{
            fontSize: "24px", fontWeight: 800, letterSpacing: "-0.5px",
            marginBottom: "10px",
            paddingBottom: "10px",
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
          }}
        >
          Users
        </Typography>
      </Grid>
      <Grid item>
        <DataGridTable
          // onCellClick={(params, event) => {
          //   console.log({ params })
          //   // event.defaultMuiPrevented = true;
          //   if (params.field !== "status") return navigate(`/application/${params.id}`);
          //   handleCellClick(params, event)
          // }}
          data={rows || []}
          columns={masterColumns}
          rowsPerPageOptions={[10]}
          pageSize={10}
          rowCount={rowCountState}
          params={params}
          setParams={setParams}
          page={page || 0}
          isLoading={false} // isLoading
          onCellEditStop={handleCellEditCommit}
        />
        {/* <DataGrid
          autoHeight
          autoPageSize
          pagination
          editMode="cell"
          // onCellClick={(params, event) => {
          //   console.log({ params })
          //   event.defaultMuiPrevented = true;
          //   if (params.field !== "status") navigate(`/ application / ${ params.id } `);
          // }}
          // onRowClick={(event) => {
          //   console.log(event);
          // }}
          rows={rows || []}
          columns={masterColumns}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          disableSelectionOnClick
          style={{ background: "#ffffff", minHeight: "60vh", border: "none" }}
          // initialState={initialState}
          // density="compact"
          pageSize={10}
          rowCount={rowCountState}
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page}
          // loading={isLoading}
          sx={{
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '7px', height: "7px"
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: '#999',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: '#616161',
            },
          }}
        /> */}
      </Grid>
    </Grid>
  );
}
