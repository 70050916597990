import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Provider } from 'react-redux';
import  store  from './store'

import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import CacheBuster from "react-cache-buster";
import PackageJson from "../package.json";
import PageLoader from './components/Application/PageLoader';

// ----------------------------------------------------------------------
const isProduction = process.env.NODE_ENV === "production";

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
    <CacheBuster
      currentVersion={PackageJson?.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<PageLoader></PageLoader>} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <Provider store={store}>
        <App />
      </Provider>
      </CacheBuster>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
