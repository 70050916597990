import React from "react";
import { NumericFormat } from "react-number-format";

export const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        decimalScale={2}
        fixedDecimalScale
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  }
);

export const currencyMaskedValue = (value) => {
  const val = value.replace(/[$,]/g, "");
  // return `${val}.00`;
  return val;
};
