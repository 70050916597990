import { Alert, IconButton, Snackbar } from "@mui/material";
import React from "react";

const CustomSnackBar = ({ Open, onClose }) => {
  return (
    <React.Fragment>
      <Snackbar open={Open} onClose={onClose}>
        {Open ? (
          <Alert
            severity="success"
            onClose={onClose}
            sx={{ height: "180px", padding: "70px 5px 70px 5px" }}
          >
            application email send successfully
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={onClose}
            sx={{ height: "180px", padding: "70px 5px 70px 5px" }}
          >
            something went wrong
          </Alert>
        )}
      </Snackbar>
    </React.Fragment>
  );
};

export default CustomSnackBar;
