import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { lowerCase, merge, startCase, upperFirst } from "lodash";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  CardActions,
  Button,
  Stack,
  Grid,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@mui/material";
import uuid from "uuid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { LoadingButton } from "@mui/lab";
import {
  userDetailsSelector,
  getApplicationDownloadUrl,
  submitApplication,
  loanDetailsSelector,
  updateLoanDetails,
  saveValue,
  getCurrentProductLenderId,
  sendDisclosure,
  verifiedDialogBox,
  getProductSubmitDetail,
} from "src/store/slices/applicationFormSlice";
import { useSnackbar } from "notistack";
import { userSelector } from "src/store/slices/userSlice";
import { Link } from "react-router-dom";
import { socket } from "src/socket";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE } from "src/constants";
import ConnectiveSendDisclosure from "../customComponents/ConnectiveSendDisclosure";
import BmaSendDisclosure from "../customComponents/BmaSendDisclosure";


const headerBackgroundColors = {
  0: "#e0f2f1",
  1: "#f1f8e9",
  2: "#fffde7",
  3: "#fff3e0",
};

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

const chipColor = {
  ["Standard"]: "#4527a0",
  ["Low doc"]: "#ba68c8",
  ["Light doc"]: "#64b5f6",
  ["Full doc"]: "#607d8b",
  ["Adverse"]: "#3f51b5",
};

export const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  (props) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 400,
      fontSize: props.theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      padding: props.tooltipPadding,
    },
  }),
);

 const Product = React.memo(({
  productDetails,
  application,
  user,
  screenSize,
  onShowRequired,
}) => {
  const dispatch = useDispatch();

    const emailObject = productDetails?.lenderCredentials?.find(
      (obj) => obj.attribute === "email"
    );

    const { availableDataLength, totalLength, remainingDataFields } =
      productDetails?.requiredFieldsData;

    const remainingDataFieldsEntity = remainingDataFields?.entity?.flat();
    const remainingDataFieldsCustomer = remainingDataFields?.customer?.flat();
    const platformName = process.env.REACT_APP_PLATFORM_NAME;

    const [expanded, setExpanded] = useState(false);
    const [openDisclosureConnectiveDialog, setOpenDisclosureConnectiveDialog] =
      useState(false);
      const [openDisclosureBmaDialog, setOpenDisclosureBmaDialog] = useState(false);
    const [disableApplicationBtn, setDisableApplicationBtn] = useState(false);
    const { open, url, downloadApplicationLoader } =
      useSelector(userDetailsSelector);
    const { currentProductLenderId } = useSelector(userDetailsSelector);
    const fit = productDetails?.fit;
    const qualification = productDetails?.qualification;
    const loanDetails = useSelector(loanDetailsSelector);
    const { enqueueSnackbar } = useSnackbar();

    const [disclosureFields, setDisclosureFields] = useState({
      rate: productDetails.rate,
      repayments: productDetails.repayments,
      originationFee:
        productDetails.fees?.find((fee) => fee.name === "Origination fee")
          ?.value || 0,
      inspectionFee: 0,
      applicationFee:
        productDetails.fees?.find((fee) => fee.name === "Document fee")
          ?.value ||
        productDetails.fees?.find((fee) => fee.name === "Private sale fee")
          ?.value ||
        0,
      effectiveRate: 0,
      registrationFee: 0,
      establsihmentFee: 0,
      accountFee:
        productDetails.fees?.find((fee) => fee.frequency === "monthly")
          ?.value || 0,
      referralFee: 0,
    });

    const emailApplicationConfigured = user?.lenderSettings?.find(
      (setting) => setting?.LenderEmail
    );

    const uploadApplicationConfigured = user?.lenderSettings?.find(
      (setting) =>
        setting?.brokerId ||
        setting?.organisationId ||
        setting?.username ||
        setting?.password
    );

    const isConfiguredForSubmit = user?.lenderSettings?.find(
      (setting) => setting?.lender === productDetails?.lender
    );

    const handleOpenDisclosureDialog = () => {
      switch (platformName) {
        case "connective":
          setOpenDisclosureConnectiveDialog(true);
          return;
        case "afs":
          setOpenDisclosureConnectiveDialog(true);
          return;
          case "bma":
            setOpenDisclosureBmaDialog(true);
            return

        default:
          console.log(`please check platform name ${platformName}`);
          return;
      }
    };

    const handleCloseDisclosureDialog = () => {
      switch (platformName) {
        case "connective":
          setOpenDisclosureConnectiveDialog(false);
          return
        case "afs":
          setOpenDisclosureConnectiveDialog(false);
          return;
        case "bma":
          setOpenDisclosureBmaDialog(false);
          return
        default:
          console.log(`please check platform name ${platformName}`);
          return;
      }
    };

    const handle = {
      showFields: () => {
        console.log("show field");
        onShowRequired(remainingDataFields);
      },
      onApplicationSubmitForCode: async ({ applicationId, lender }) => {
        await handle.saveQuote();

        dispatch(getCurrentProductLenderId(""));
        dispatch(saveValue({ applyApplication: null }));
        dispatch(getCurrentProductLenderId(lender));
        setDisableApplicationBtn(true);
        dispatch(verifiedDialogBox(true));
        await socket.emit("join", {
          uuid: Math.floor(100000 + Math.random() * 900000),
        });
        await dispatch(
          getProductSubmitDetail({
            applicationId: applicationId,
            lenderName: lender,
          })
        );
      },
      onApplicationSubmit: async ({
        applicationId,
        lender,
        submitType,
        productDetails,
      }) => {
        await handle.saveQuote();

        dispatch(getCurrentProductLenderId(""));
        dispatch(saveValue({ applyApplication: null }));
        dispatch(getCurrentProductLenderId(lender));
        setDisableApplicationBtn(true);
        dispatch(
          submitApplication({
            submitType: submitType,
            applicationId: applicationId,
            lenderType: lender === "Angle Finance" ? "Angle" : lender,
            productDetails,
          })
        ).then((res) => {
          dispatch(saveValue({ applyApplication: true }));
          setDisableApplicationBtn(false);
          dispatch(getCurrentProductLenderId(""));

          if (res.payload?.data?.data) {
            enqueueSnackbar(res.payload?.status_message || SUCCESS_MESSAGE.link_msg, {
              variant: "success",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar(res.payload?.status_message || ERRORS_MESSAGE.fetch_error_msg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });
      },
    openDisclosureDialog: () => {
      // setOpenDisclosureDialog(true);
    },
    closeDisclosureDialog: () => {
      // setOpenDisclosureDialog(false);
    },
      onApplicationDownland: async ({ applicationId, lender }) => {
        dispatch(getCurrentProductLenderId(""));
        dispatch(getCurrentProductLenderId(lender));
        dispatch(
          getApplicationDownloadUrl({
            applicationId: applicationId,
          })
        ).then((res) => {
          if (res?.payload?.data?.data) {
            enqueueSnackbar(SUCCESS_MESSAGE.generate_pdf, {
              variant: "success",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar(ERRORS_MESSAGE.fetch_error_msg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });
      },
      expandClickFn: () => {
        setExpanded(!expanded);
        if (user.status === "new" && !expanded) {
          dispatch(saveValue({ walkthroughIndex: 4 }));
        }
      },
      criteriaColorFn: (result) => {
        if (result) return "#2e7d32";
        if (result === undefined) return "#999999";
        return "#c62828";
      },
      extrasStyleFn: (result) => {
        if (result === false)
          return { color: "inherit", textDecoration: "line-through" };
        if (result === undefined) return { color: "#999999" };
        return { color: "inherit" };
      },
      setHeaderBackground: () => {
        return headerBackgroundColors[qualification] || "#fbe9e7";
      },
      criteriaIconsFn: (criteria) => {
        if (criteria.result === true)
          return (
            <CheckCircleIcon
              style={{
                fontSize: "14px",
                color: handle.criteriaColorFn(criteria.result),
              }}
            />
          );
        if (criteria.result === false)
          return (
            <CancelIcon
              style={{
                fontSize: "14px",
                color: handle.criteriaColorFn(criteria.result),
              }}
            />
          );
        return (
          <RemoveCircleIcon
            style={{
              fontSize: "14px",
              color: handle.criteriaColorFn(criteria.result),
            }}
          />
        );
      },
      saveQuote: async () => {
        if (loanDetails?._id)
          await dispatch(
            updateLoanDetails(
              merge({
                ...loanDetails,
                ...{
                  loanAmount: productDetails?.loanAmount,
                  rate: productDetails?.rate,
                  repayments: productDetails?.repayments,
                  brokerage: productDetails?.brokerage,
                  // fees: handleFees(),
                },
              })
            )
          ).unwrap();
      },
    };

    const titleCaseFun = (value) => {
      let val = startCase(value?.split(/\.(?=[^\.]+$)/)[1])
        ? startCase(value?.split(/\.(?=[^\.]+$)/)[1])
        : value;

      return val;
    };

    return (
      <React.Fragment>
        {open ? (
          <Card
            className="tenth-step"
            elevation={1}
            style={{
              margin: "0 0 12px",
              background: handle.setHeaderBackground(),
              borderRadius: "8px",
            }}
          >
            <CardContent
              style={{
                padding: "0 20px",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ padding: "7px 5px 5px 0" }}
              >
                <Typography
                  variant="body2"
                  style={{
                    marginRight: "20px",

                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  <span>Criteria: {productDetails?.criteria}</span>
                </Typography>

                <Stack direction="row" spacing={1}>
                  {productDetails?.labels?.map((label) => (
                    <Chip
                      key={nanoid()}
                      size="small"
                      label={label}
                      style={{
                        padding: "0 2px",
                        height: "22px",
                        color: "#fff",
                        background: chipColor[label],
                      }}
                    />
                  ))}
                </Stack>
              </Stack>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={screenSize.dynamicWidth < 900 ? 5 : 8}
                    alignItems="center"
                  >
                    <Stack
                      direction={
                        screenSize.dynamicWidth < 900 ? "column" : "row"
                      }
                      justifyContent="center"
                      alignItems={
                        screenSize.dynamicWidth < 900 ? "start" : "center"
                      }
                    >
                      <Grid
                        item
                        xs={screenSize.dynamicWidth < 900 ? 6 : 4}
                        style={{}}
                        alignItems="center"
                      >
                        <img
                          src={productDetails?.logo}
                          style={{
                            width:
                              screenSize.dynamicWidth < 900 ? "100%" : "60%",
                            height: "auto",
                            margin: "auto",
                            display: "flex",
                          }}
                        />
                      </Grid>
                      <Grid item xs={screenSize.dynamicWidth < 900 ? 12 : 8}>
                        <Stack direction="column">
                          {screenSize.dynamicWidth > 900 && (
                            <>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="subtitle"
                                style={{ fontSize: "14px", fontWeight: "400" }}
                              >
                                {productDetails?.productName}
                              </Typography>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="subtitle"
                                style={{
                                  marginRight: "5px",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                }}
                              >
                                {productDetails?.lender}
                              </Typography>
                            </>
                          )}
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="h5"
                            style={{
                              marginRight: "5px",
                              marginBottom: "-5px",
                              fontWeight: "700",
                            }}
                          >
                            {productDetails?.repayments?.toLocaleString(
                              "en-US",
                              dollarStringOptions
                            )}
                            /mth
                          </Typography>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="caption"
                            style={{ marginRight: "5px", fontSize: "11px" }}
                          >
                            {productDetails?.monthlyFees &&
                              `incl. ${productDetails?.monthlyFees?.value?.toLocaleString(
                                "en-US",
                                dollarStringOptions
                              )} monthly fees`}
                          </Typography>
                          {screenSize.dynamicWidth > 900 && (
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body1"
                              style={{ marginRight: "5px" }}
                            >
                              {(
                                productDetails?.repayments / 4.33
                              )?.toLocaleString("en-US", dollarStringOptions)}
                              /wk
                            </Typography>
                          )}
                        </Stack>
                      </Grid>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={screenSize.dynamicWidth < 900 ? 7 : 4}
                    alignItems="center"
                  >
                    <Typography
                      sx={{ display: "inline" }}
                      variant="body2"
                      style={{
                        marginRight: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Lender rate:{" "}
                      <span>{(1 * productDetails?.rate).toFixed(2)}%</span>
                    </Typography>

                    <Typography
                      sx={{ display: "inline" }}
                      variant="body2"
                      style={{
                        marginRight: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Effective rate:{" "}
                      <span>
                        {(1 * productDetails?.effectiveRate).toFixed(2)}%
                      </span>
                    </Typography>

                    <Typography
                      sx={{ display: "inline" }}
                      // component="span"
                      variant="body2"
                      style={{
                        marginRight: "5px",

                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Finance amount:
                      <span>
                        {productDetails?.financeAmount?.toLocaleString(
                          "en-US",
                          dollarStringOptions
                        )}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ display: "inline" }}
                      variant="body2"
                      style={{
                        marginRight: "5px",

                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Brokerage
                      <span>
                        {productDetails?.brokerage?.toFixed(2)}% /{" "}
                        {productDetails?.brokerageAmount?.toLocaleString(
                          "en-US",
                          dollarStringOptions
                        )}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ display: "inline" }}
                      variant="body2"
                      color={productDetails?.score ? "#222222" : "aaaaaa"}
                      style={{
                        marginRight: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Stack
                        direction="column"
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Stack direction="row" justifyContent="space-between">
                          <span>Total upfront fees:</span>
                          <span>
                            {productDetails?.feesTotal?.toLocaleString(
                              "en-US",
                              dollarStringOptions
                            )}
                          </span>
                        </Stack>
                      </Stack>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <CardActions
              disableSpacing
              style={{
                justifyContent: "space-between",
                padding: "10px 20px",
              }}
            >
              <Button
                className="fifth-step"
                size="small"
                expand={expanded}
                onClick={handle.expandClickFn}
                aria-expanded={expanded}
                aria-label="show more"
                color="secondary"
                style={{
                  padding: "0 10px",
                  minWidth: "auto",
                  fontWeight: "bold",
                }}
              >
                {expanded ? (
                  <React.Fragment>
                    Hide <ExpandLessIcon />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Show criteria <ExpandMoreIcon />
                  </React.Fragment>
                )}
              </Button>

              <Stack spacing={1} direction="row" justifyContent="center">
                {remainingDataFields && (
                  <HtmlTooltip
                    // disableHoverListener={isConfiguredForSubmit}
                    tooltipPadding={"20px 20px 5px 20px"}
                    title={
                      <React.Fragment>
                        <Typography
                          color="inherit"
                          style={{
                            marginBottom: "20px",
                            fontWeight: "600",
                            borderBottom: "1px solid #ddd",
                            paddingBottom: "15px",
                          }}
                        >
                          Required fields remaining
                        </Typography>
                        <Stack
                          sx={{
                            overflowY: "auto",
                            overflowX: "hidden",
                            height: "280px",
                            scrollbarColor: "white rebeccapurple",
                            scrollbarWidth: "thin",
                            "&::-webkit-scrollbar": {
                              width: "7px",
                              height: "7px",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#999",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#616161",
                            },
                          }}
                        >
                          {/* <div style={{ maxHeight: "280px", overflow: "auto" }}> */}
                          {remainingDataFields?.asset?.length > 0 && (
                            <Typography
                              color="inherit"
                              marginBottom="5px"
                              variant="body2"
                              sx={{ fontWeight: 600 }}
                            >
                              Assets:
                            </Typography>
                          )}
                          {remainingDataFields?.asset?.map((field) => (
                            <React.Fragment>
                              <Typography
                                color="inherit"
                                fontSize="14px"
                                marginBottom="10px"
                                sx={{
                                  "&:hover": {
                                    color: "#d32f2f",
                                  },
                                }}
                              >
                                {upperFirst(lowerCase(field))}

                                {/* {startCase(field?.split(/\.(?=[^\.]+$)/)[1])} */}
                              </Typography>
                            </React.Fragment>
                          ))}
                          {application?.customers?.length > 0 && (
                            <React.Fragment>
                              {remainingDataFieldsCustomer?.length > 0 && (
                                <Typography
                                  color="inherit"
                                  marginBottom="5px"
                                  variant="body2"
                                  sx={{ fontWeight: 600 }}
                                >
                                  Guarantor:
                                </Typography>
                              )}
                              {remainingDataFieldsCustomer?.map(
                                (field, index) => (
                                  <React.Fragment>
                                    <Typography
                                      color="inherit"
                                      fontSize="14px"
                                      marginBottom="10px"
                                      sx={{
                                        "&:hover": {
                                          color: "#d32f2f",
                                        },
                                      }}
                                    >
                                      {/* Hard Code 1 is there because we can't manage index directly */}
                                      {/* Guarantor 1{" "} */}
                                      {upperFirst(
                                        lowerCase(titleCaseFun(field))
                                      ) === "Address"
                                        ? "email"
                                        : upperFirst(
                                            lowerCase(titleCaseFun(field))
                                          )}
                                    </Typography>
                                  </React.Fragment>
                                )
                              )}
                            </React.Fragment>
                          )}
                          {application?.entities?.length > 0 && (
                            <React.Fragment>
                              {remainingDataFieldsEntity?.length > 0 && (
                                <Typography
                                  color="inherit"
                                  marginBottom="5px"
                                  variant="body2"
                                  sx={{ fontWeight: 600 }}
                                >
                                  Entity:
                                </Typography>
                              )}
                              {remainingDataFieldsEntity?.map((field) => (
                                <React.Fragment>
                                  <Typography
                                    color="inherit"
                                    fontSize="14px"
                                    marginBottom="10px"
                                    sx={{
                                      "&:hover": {
                                        color: "#d32f2f",
                                      },
                                    }}
                                  >
                                    {upperFirst(lowerCase(titleCaseFun(field)))}
                                  </Typography>
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          )}
                          {remainingDataFields?.agreement?.length > 0 && (
                            <Typography
                              color="inherit"
                              marginBottom="5px"
                              variant="body2"
                              sx={{ fontWeight: 600 }}
                            >
                              Agreement:
                            </Typography>
                          )}
                          {remainingDataFields?.agreement?.map((field) => (
                            <React.Fragment>
                              <Typography
                                color="inherit"
                                fontSize="14px"
                                marginBottom="10px"
                                sx={{
                                  "&:hover": {
                                    color: "#d32f2f",
                                  },
                                }}
                              >
                                {upperFirst(lowerCase(field))}
                              </Typography>
                            </React.Fragment>
                          ))}
                        </Stack>
                        {/* <Stack
                          sx={{
                            marginTop: "20px",
                            fontWeight: "600",
                            borderTop: "1px solid #ddd",
                            paddingTop: "5px",
                            width: "100%",
                          }}
                        >
                          <Button onClick={handle.showFields}>
                            Show Fields
                          </Button>
                        </Stack> */}
                      </React.Fragment>
                    }
                  >
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ margin: "0 10px" }}
                    >
                      <Typography variant="subtitle1" fontWeight={600}>
                        Required fields:
                      </Typography>

                      <Typography variant="subtitle1" fontWeight={600}>
                        {/* data available / Required field length */}
                        {availableDataLength || 0} / {totalLength || 0}
                      </Typography>
                    </Stack>
                  </HtmlTooltip>
                )}

            { ["connective","afs","bma"].includes(platformName)  && <span>
                  <LoadingButton
                    color="secondary"
                    endIcon={<SendOutlinedIcon />}
                    loadingPosition="end"
                    variant="outlined"
                    size="small"
                    style={{
                      padding: "0 10px",
                      minWidth: "auto",
                      fontWeight: 600,
                    }}
                    onClick={async () => {
                      //   handle.saveQuote();
                      //   await dispatch(
                      //     sendDisclosure({
                      //       applicationId: application._id,
                      //       lenderType: productDetails?.lender,

                      //     })
                      //   ).unwrap();
                      handleOpenDisclosureDialog();
                    }}
                  >
                    Send Disclosure
                  </LoadingButton>
                </span>}

                <span>
                  <LoadingButton
                    color="secondary"
                    endIcon={<SendOutlinedIcon />}
                    loadingPosition="end"
                    variant="outlined"
                    size="small"
                    style={{
                      padding: "0 10px",
                      minWidth: "auto",
                      fontWeight: 600,
                    }}
                    disabled={
                      (currentProductLenderId === productDetails?.lender &&
                        downloadApplicationLoader) ||
                      availableDataLength !== totalLength
                    }
                    onClick={async () => {
                      handle.onApplicationDownland({
                        applicationId: application._id,
                        lender: productDetails?.lender,
                      });
                    }}
                    href={url}
                  >
                    {downloadApplicationLoader &&
                    currentProductLenderId === productDetails?.lender
                      ? "Sending"
                      : "Download application"}
                  </LoadingButton>
                </span>
                {productDetails?.actions?.includes("email") && (
                  <HtmlTooltip
                    tooltipPadding={"20px"}
                    disableHoverListener={
                      isConfiguredForSubmit && emailApplicationConfigured
                    }
                    title={
                      !emailObject && (
                        <React.Fragment>
                          <Typography color="inherit" marginBottom="5px">
                            Looks like you're not setup with{" "}
                            {productDetails.lender}.
                          </Typography>
                          <Typography
                            color="inherit"
                            fontSize="14px"
                            marginBottom="10px"
                          >
                            Click on the link below to add your credentials.
                          </Typography>
                          <Link to="/settings/lenders">
                            <Button variant="outlined" size="small">
                              Add credentials
                            </Button>
                          </Link>
                        </React.Fragment>
                      )
                    }
                  >
                    <span>
                      <LoadingButton
                        color="secondary"
                        endIcon={<SendOutlinedIcon />}
                        loadingPosition="end"
                        variant="outlined"
                        size="small"
                        style={{
                          padding: "0 10px",
                          minWidth: "auto",
                          fontWeight: 600,
                        }}
                        disabled={
                          (disableApplicationBtn &&
                            currentProductLenderId ===
                              productDetails?.lender) ||
                          availableDataLength !== totalLength
                        }
                        onClick={async () => {
                          handle.onApplicationSubmit({
                            submitType: "email",
                            applicationId: application._id,
                            lender: productDetails?.lender,
                            productDetails: {
                              productName: productDetails.productName,
                              productType: productDetails.productType,
                            },
                          });
                        }}
                      >
                        {disableApplicationBtn &&
                        currentProductLenderId === productDetails?.lender
                          ? "Sending"
                          : "Email application"}
                      </LoadingButton>
                    </span>
                  </HtmlTooltip>
                )}
                {productDetails?.actions?.includes("upload") && (
                  <HtmlTooltip
                    tooltipPadding={"20px"}
                    disableHoverListener={
                      isConfiguredForSubmit && uploadApplicationConfigured
                    }
                    title={
                      !emailObject && (
                        <React.Fragment>
                          <Typography color="inherit" marginBottom="5px">
                            Looks like you're not setup with
                            {productDetails.lender}.
                          </Typography>
                          <Typography
                            color="inherit"
                            fontSize="14px"
                            marginBottom="10px"
                          >
                            Click on the link below to add your credentials.
                          </Typography>
                          <Link to="/settings/lenders">
                            <Button variant="outlined" size="small">
                              Add credentials
                            </Button>
                          </Link>
                        </React.Fragment>
                      )
                    }
                  >
                    <span>
                      <LoadingButton
                        color="secondary"
                        endIcon={<SendOutlinedIcon />}
                        loadingPosition="end"
                        variant="outlined"
                        size="small"
                        // disabled={
                        //   availableDataLength === totalLength ? false : true
                        // }
                        style={{
                          padding: "0 10px",
                          minWidth: "auto",
                          fontWeight: 600,
                        }}
                        disabled={
                          (disableApplicationBtn &&
                            currentProductLenderId ===
                              productDetails?.lender) ||
                          availableDataLength !== totalLength
                        }
                        onClick={async () => {
                          productDetails?.lender === "Angle Finance"
                            ? handle.onApplicationSubmitForCode({
                                submitType: "upload",
                                applicationId: application._id,
                                lender: productDetails?.lender,
                                productDetails: {
                                  productName: productDetails.productName,
                                  productType: productDetails.productType,
                                },
                              })
                            : handle.onApplicationSubmit({
                                submitType: "upload",
                                applicationId: application._id,
                                lender: productDetails?.lender,
                                productDetails: {
                                  productName: productDetails.productName,
                                  productType: productDetails.productType,
                                },
                              });
                        }}
                      >
                        {disableApplicationBtn &&
                        currentProductLenderId === productDetails?.lender
                          ? "Uploading"
                          : "Upload application"}
                      </LoadingButton>
                    </span>
                  </HtmlTooltip>
                )}
              </Stack>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent style={{ padding: "5px 20px 10px" }}>
                <Grid item container spacing={3}>
                  <Grid
                    item
                    className="sixth-step"
                    xs={screenSize.dynamicWidth < 900 ? 7 : 4}
                  >
                    <Typography variant="subtitle">
                      <strong>Criteria</strong>
                    </Typography>
                    {productDetails?.criteriaResults
                      ?.sort((x, y) => {
                        if (x.result && x.result !== y.result) return -1;
                        if (x.result === undefined && y.result) return 1;
                        if (y.result === undefined && !x.result) return 1;
                        if (x.result === y.result) return 0;
                        if (!y.result) return -1;
                      }) // sort from true to false
                      ?.map((criteria) => (
                        <Stack direction="row" alignItems="center">
                          {handle.criteriaIconsFn(criteria)}
                          <Typography
                            variant="body2"
                            style={{ marginLeft: "5px" }}
                          >
                            {criteria.label}
                          </Typography>
                        </Stack>
                      ))}
                  </Grid>
                  {(productDetails?.loading?.loadingList?.[0] ||
                    productDetails?.fees?.[0]) && (
                    <Grid item xs={screenSize.dynamicWidth < 900 ? 5 : 4}>
                      <Typography variant="subtitle">
                        <strong>Extras</strong>
                      </Typography>

                      <React.Fragment>
                        {productDetails?.loading?.loadingList
                          ?.sort((x, y) => {
                            if (x.result && x.result !== y.result) return -1;
                            if (x.result === undefined && y.result) return 1;
                            if (y.result === undefined && !x.result) return 1;
                            if (x.result === y.result) return 0;
                            if (!y.result) return -1;
                          })
                          ?.map((load) => (
                            <Stack
                              // key={fee?.name}
                              direction="row"
                              justifyContent="space-between"
                              style={handle.extrasStyleFn(load.result)}
                            >
                              <Typography
                                // key={load?.name}
                                variant="body2"
                                // color={handle.criteriaColorFn(load.result)}
                              >
                                {load?.name}:
                              </Typography>
                              <Typography
                                // key={load?.name}
                                variant="body2"
                                // color={handle.criteriaColorFn(load.result)}
                              >
                                {load?.value}%
                              </Typography>
                            </Stack>
                          ))}
                        {productDetails?.fees?.map((fee) => (
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">
                              {fee?.name}:
                            </Typography>
                            <Typography variant="body2">
                              {fee?.value?.toLocaleString(
                                "en-US",
                                dollarStringOptions
                              )}
                            </Typography>
                          </Stack>
                        ))}
                      </React.Fragment>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={
                      productDetails?.loading?.loadingList?.[0] ||
                      productDetails?.fees?.[0]
                        ? screenSize.dynamicWidth < 900
                          ? 12
                          : 4
                        : screenSize.dynamicWidth < 900
                        ? 12
                        : 7
                    }
                  >
                    {productDetails?.info?.[0] && (
                      <>
                        <Typography variant="subtitle">
                          <strong>Info & Requirements</strong>
                        </Typography>
                        <ul
                          style={{
                            fontSize: "12.5px",
                            listStyle: "inside",
                            textAlign: "justify",
                          }}
                        >
                          {productDetails?.info?.map((info) => (
                            <li>{info}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Box
                  style={{
                    margin: "10px 0 0",
                    textAlign: "left",
                  }}
                >
                  <Typography variant="caption">
                    Lender rates as of: {productDetails?.date}
                  </Typography>
                </Box>
              </CardContent>
            </Collapse>
          </Card>
        ) : (
          <Card
            alignItems="flex-start"
            style={{
              margin: "0 0 10px 0",
              background: handle.setHeaderBackground(),
            }}
          >
            <CardContent style={{ padding: "5px 10px" }}>
              <Stack
                direction="row"
                spacing={2}
                style={{
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <img width="60px" alt="Remy Sharp" src={productDetails.logo} />
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    {productDetails.criteria} criteria
                  </Typography>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    style={{ marginRight: "5px", fontSize: "14px" }}
                  >
                    <span>${productDetails?.repayments?.toFixed(2)}/mth</span>
                  </Typography>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    style={{ marginRight: "5px", fontSize: "14px" }}
                  >
                    <span>{productDetails?.rate.toFixed(2)}%</span>
                  </Typography>
                  {/* <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  style={{ marginRight: "5px", fontSize: "14px" }}
                >
                  <span>
                    {productDetails?.brokerage?.toFixed(2)}% / {productDetails?.brokerageAmount?.toLocaleString(
                      "en-US",
                      dollarStringOptions
                    )}
                  </span>
                </Typography> */}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}

        <ConnectiveSendDisclosure
        productDetails={productDetails}
        application={application}
          openDisclosureDialog={openDisclosureConnectiveDialog}
          handleOpenDisclosureDialog={handleOpenDisclosureDialog}
          handleCloseDisclosureDialog={handleCloseDisclosureDialog}
          disclosureFields={disclosureFields}
          setDisclosureFields={setDisclosureFields}
        />
        <BmaSendDisclosure 
         productDetails={productDetails}
         application={application}
         disclosureFields={disclosureFields}
         openDisclosureDialog={openDisclosureBmaDialog}
         setDisclosureFields={setDisclosureFields}
         handleOpenDisclosureDialog={handleOpenDisclosureDialog}
         handleCloseDisclosureDialog={handleCloseDisclosureDialog}
        />
      </React.Fragment>
    );
  }
);

export default Product;
